<template>
  <gw-dialog width="auto" v-model="dialog">
    <template #activator="{ on }">
      <v-btn color="primary" depressed v-on="on">
        {{ $t("create.from.template") }}
      </v-btn>
    </template>

    <template #title>
      <v-toolbar flat>
        <v-toolbar-title v-text="$t('choose.template')" />
      </v-toolbar>
    </template>

    <v-list two-line outlined class="py-0">
      <template v-for="(item, index) in arItems">
        <v-list-item
          color="primary"
          :key="`payment.term.${item.name}`"
          @click="onSelect(item)"
        >
          <v-list-item-content>
            <v-list-item-title
              class="primary--text font-weight-bold"
              v-text="item.name"
            />
            <v-list-item-subtitle v-text="item.description" />
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="index < arItems.length - 1"
          :key="`payment.term.divider.${index}`"
        />
      </template>
    </v-list>
  </gw-dialog>
</template>

<script lang="ts">
import type { PaymentTermData } from "@planetadeleste/vue-mc-gw/src/types";
import { Component, Vue } from "vue-property-decorator";

import GwDialog from "@/components/common/GwDialog.vue";

@Component({
  components: { GwDialog },
})
export default class PaymentTermsTemplateDialog extends Vue {
  dialog = false;

  arItems: Partial<PaymentTermData>[] = [
    {
      name: "CRÉDITO A 30 DÍAS",
      frequency: "day",
      frequency_interval: 1,
      cycles: 1,
      delay: 30,
      description: "Crédito a 30 días, pagando el total a los 30 días",
    },
    {
      name: "CRÉDITO A 60 DÍAS",
      frequency: "day",
      frequency_interval: 1,
      cycles: 1,
      delay: 60,
      description: "Crédito a 60 días, pagando el total a los 60 días",
    },
    {
      name: "CRÉDITO A 90 DÍAS",
      frequency: "day",
      frequency_interval: 1,
      cycles: 1,
      delay: 90,
      description: "Crédito a 90 días, pagando el total a los 90 días",
    },
    {
      name: "CRÉDITO A 120 DÍAS",
      frequency: "day",
      frequency_interval: 1,
      cycles: 1,
      delay: 120,
      description: "Crédito a 120 días, pagando el total a los 120 días",
    },
    {
      name: "6 CUOTAS SIN ENTREGA",
      frequency: "month",
      frequency_interval: 1,
      cycles: 6,
      delay: 30,
      description: "Crédito en 6 cuotas, pagando la primera a los 30 días",
    },
    {
      name: "12 CUOTAS SIN ENTREGA",
      frequency: "month",
      frequency_interval: 1,
      cycles: 12,
      delay: 30,
      description: "Crédito en 12 cuotas, pagando la primera a los 30 días",
    },
    {
      name: "24 CUOTAS SIN ENTREGA",
      frequency: "month",
      frequency_interval: 1,
      cycles: 24,
      delay: 30,
      description: "Crédito en 24 cuotas, pagando la primera a los 30 días",
    },
    {
      name: "24 CUOTAS CON ENTREGA",
      frequency: "month",
      frequency_interval: 1,
      cycles: 24,
      delay: 0,
      description: "Crédito en 24 cuotas, pagando la primera en el momento",
    },
    {
      name: "48 CUOTAS CON ENTREGA",
      frequency: "month",
      frequency_interval: 1,
      cycles: 48,
      delay: 0,
      description: "Crédito en 48 cuotas, pagando la primera en el momento",
    },
  ];

  onSelect(obItem: Partial<PaymentTermData>) {
    this.$emit("change", obItem);
    this.dialog = false;
  }
}
</script>
